












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    textColor: {
      type: String,
      default: 'black'
    },
    customTitle: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    desc1: {
      type: String,
      default: ''
    },
    desc2: {
      type: String,
      default: ''
    },
  },
  components :{
    CustomHeading: () => import('./CDashedTitle.vue'),
  },
  setup() {
    
  },
})
